
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

$vdmi-orange1:#FFA500;
$vdmi-orange2:#F5BB55;
$vdmi-orange3:#FFDD88;
$vdmi-orange4:#FFEE99;
$vdmi-blau:#226088;
$vdmi-complement1:#6C7870;
$vdmi-complement2:#BFAFAD;
$vdmi-complement3:#F2F1EF;


// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Helvetica", sans-serif;
$font-family-secondary:'Roboto Condensed', sans-serif;
$line-height-base: 1.4;
$font-size-base: 12px !default;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #EEE; // doesn't work, mmai
// this works:
$heading-bg-color:#EEE;
