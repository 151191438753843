// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "select2";

html, body, .container-table {
  background-color: #FFF;
  height: 100%;
}

.bg-row-0 {
  background-color: lighten($vdmi-orange1, 30%);
}

.bg-row-1 {
  background-color: lighten($vdmi-orange1, 35%);
}

.mi-form-inline {
  display: inline;
}

// For vertical alignment:
.container-table {
  display: table;
}

.vertical-center-row {
  display: table-cell;
  vertical-align: middle;
}

.minav {
  font-family: $font-family-secondary;
}

table {
  max-width: none;
}

.mi-tabcontent {
  padding: 10px 0;
}

#Branches {

  a {
    color: #FFF;
    text-decoration: none;
    display: block;
    padding: 3px 10px;
    margin-bottom: 2px;
  }

  ul, li {
    list-style-type: none;
    padding-left: 10px;
  }

  .branch a {
    background-color: $vdmi-orange1;
    border: 1px solid darken($vdmi-orange1, 20%);
    color: #FFF;
  }

  .team a {
    background-color: $vdmi-orange2;
    border: 1px solid darken($vdmi-orange2, 20%);
    color: #222;
  }

  .seller a {
    background-color: $vdmi-orange3;
    border: 1px solid darken($vdmi-orange3, 20%);
    color: #222;
  }

  .salesregion a {
    background-color: $vdmi-orange4;
    border: 1px solid darken($vdmi-orange4, 20%);
    color: #222;
  }

  .salesregionplz a {
    background-color: #EEE;
    border: 1px solid darken($vdmi-orange1, 20%);
    color: #222;
  }

}

// Evaluations:
.evaluation {
  table {
    background-color: #FFF;
  }
  th, td {
    font-size: 11px;
    padding: 3px 2px !important;
  }
}

// Own styles:
$panel-title-color: #FFF;

.panel-default {
  .panel-heading {
    color: $panel-title-color;
    background-color: $vdmi-blau;
    border-color: darken($vdmi-blau, 10%);
  }
  .panel-body {
    background-color: #EEE;
  }
}

.table {
  background-color: #FFF;
}

.btn-default {
  background-color: $vdmi-complement3;
  &:hover {
    color: #636b6f;
    background-color: #e6e5e5;
    border-color: #adadad;
  }
}

//#userinfo {
//  text-decoration: underline;
//}

.mitopbar {
  padding: 15px 0;
  background-color: $vdmi-blau;
  color: $vdmi-orange1;
  .milogo, a {
    color: $vdmi-orange1;
    text-decoration: underline;
  }
  .active_country {
    color: #FFF;
    font-weight: bold;
  }
}

.mi-padding {
  padding: 10px;
}

.mipanel {
  .panel-heading a {
    text-decoration: none;
    color: $panel-title-color;
    display: block;
    width: 100%;
    &:after {
      content: attr(data-button-icon);
      float: right;
      padding-left: 1em;
    }
  }
}

.mi-col-table {
  display: table;
  .mi-bottom-element {
    height:50px;
    min-height: 50px;
    display: table-cell;
    vertical-align: bottom;
  }
}



